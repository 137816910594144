import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"user-tab-notification"},[_c(VCardTitle,[_vm._v(" Notifications ")]),_c(VDivider),_c(VCardText,{staticClass:"text--primary font-weight-medium"},[_vm._v(" You will receive notification for the below selected items. ")]),_c(VDataTable,{attrs:{"headers":_vm.tableColumn,"items":_vm.notifications,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCheckbox,{staticClass:"shrink",attrs:{"hide-details":"","dense":""},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})],1)]}},{key:"item.browser",fn:function(ref){
var item = ref.item;
return [_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCheckbox,{staticClass:"shrink",attrs:{"hide-details":"","dense":""},model:{value:(item.browser),callback:function ($$v) {_vm.$set(item, "browser", $$v)},expression:"item.browser"}})],1)]}},{key:"item.app",fn:function(ref){
var item = ref.item;
return [_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCheckbox,{staticClass:"shrink",attrs:{"hide-details":"","dense":""},model:{value:(item.app),callback:function ($$v) {_vm.$set(item, "app", $$v)},expression:"item.app"}})],1)]}}],null,true)}),_c(VDivider),_c(VCardActions,[_c(VBtn,{staticClass:"me-3",attrs:{"color":"primary"}},[_vm._v(" save changes ")]),_c(VBtn,{attrs:{"color":"secondary","outlined":""}},[_vm._v(" Discard ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }